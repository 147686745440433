<template>
  <div class="products-page">
    <b-container fluid>
      <b-row>
        <b-col class="my-3">
          <h2 class="text-gray">ابرز منتجاتنا</h2>
        </b-col>
      </b-row>
    </b-container>
    <hr />
    <b-container fluid>
      <b-row>
        <b-col cols="12" md="12" lg="12" order="2" order-md="2">
          <main>
            <b-row>
              <b-col
                class="my-5"
                v-for="item in products"
                :key="item.name"
                cols="12"
                sm="6"
                lg="4"
              >
                <product-card :product="item"> </product-card>
              </b-col>
            </b-row>
          </main>
        </b-col>
        <b-col cols="12" order="1" md="12" lg="4" order-lg="2">
          <!-- <aside class="position-fixed">
            <b-card
              class="shadow w-100 rounded overflow-hidden"
              header-class="back-yello"
            >
              <template #header>
                <h6 class="text-dark">تصفية النتائج</h6>
              </template>
              <b-form-group>
                <b-input-group class="d-flex flex-column">
                  <label for="">بحث عن منتج</label>
                  <b-input class="w-100"> </b-input>
                </b-input-group>
                <hr />
                <div class="form-group">
                  <label for="">التصنيف</label>
                  <b-select> </b-select>
                </div>
                <div class="form-group">
                  <label for="">التصنيف الثانوي</label>
                  <b-select> </b-select>
                </div>
                <div class="form-group">
                  <label for="">الفئة العمرية</label>
                  <b-select> </b-select>
                </div>
                <div class="form-group">
                  <label for="">الوسوم</label>
                  <b-select> </b-select>
                </div>
              </b-form-group>
            </b-card>
          </aside> -->
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import ProductCard from "./components/ProductCard.vue";
export default {
  components: {
    ProductCard,
  },
  data() {
    return {
      products: [
        {
          name: "أنا والباندا",
          lang: "عربي",
          category: "مناهج-اطفال",
          desc:
            "نهج شامل متكامل لجيل واعد متعلم ... وهو منهج يساعد المعلم والمربي على تعليم الأطفال بطريقة تفاعلية ممتعة...",
          image: "/images/products/panda.png",
        },
        {
          name: " الفقه الاسلامي الميسر",
          lang: "عربي",
          category: "ديني-اسلامي",
          desc:
            "كتاب فقهي شامل لأحكام العبادات على المذاهب الأربعة ملتزم بذكر الراجح منها , مع تيسير الأسلوب ودقة في المقارنة",
          image: "/images/products/foqeh.svg",
        },
        {
          name: "الجزء الرشيد 1",
          lang: "عربي",
          category: "تعليمي-لغات",
          desc:
            "تعد هذه السلسة عمدة رئيسية في تعليم الطلاب المبتدئين وغير الناطقين بالعربية قوانين القراءة العربية الصحيحة , مع سهولة في الأسلوب , وتشويق في العرض",
          image: "/images/products/rashed-1.svg",
        },
        {
          name: "الجزء الرشيد 2",
          lang: "عربي",
          category: "تعليمي-لغات",
          desc:
            "تعد هذه السلسة عمدة رئيسية في تعليم الطلاب المبتدئين وغير الناطقين بالعربية قوانين القراءة العربية الصحيحة , مع سهولة في الأسلوب , وتشويق في العرض",
          image: "/images/products/rashed-2.svg",
        },
        {
          name: "الجزء الرشيد 3",
          lang: "عربي",
          category: "تعليمي-لغات",
          desc:
            "تعد هذه السلسة عمدة رئيسية في تعليم الطلاب المبتدئين وغير الناطقين بالعربية قوانين القراءة العربية الصحيحة , مع سهولة في الأسلوب , وتشويق في العرض",
          image: "/images/products/roqaa.svg",
        },
        {
          name: "الجزء الرشيد 5",
          lang: "عربي",
          category: "تعليمي-لغات",
          desc:
            "تعد هذه السلسة عمدة رئيسية في تعليم الطلاب المبتدئين وغير الناطقين بالعربية قوانين القراءة العربية الصحيحة , مع سهولة في الأسلوب , وتشويق في العرض",
          image: "/images/products/rashed-3.svg",
        },
      ],
    };
  },
};
</script>

<style lang="scss">
.products-page {
  min-height: 100vh;
}
</style>
