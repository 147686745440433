<template>
  <div>
    <div class="product-card shadow">
      <div
        class="product-card-text w-100 h-100 d-flex flex-column align-items-center justify-content-center position-relative"
      >
        <span class="text-yello">{{ product.lang }}</span>
        <h4 class="text-light m-0 p-3 text-center">{{ product.name }}</h4>
        <h6 class="text-light">{{ product.category }}</h6>
      </div>
      <div class="product-card-image w-auto">
        <img :src="product.image" alt="" />
      </div>
      <div class="product-card-details w-100 h-100">
        <div class="content">
          <span class="px-1">
            {{ product.desc }}
          </span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["product"],
};
</script>

<style lang="scss">
body {
  overflow: hidden;
}
.product-card {
  background-color: #2b2b2b;
  border-radius: 1rem;
  padding-left: 50% !important;
  height: 200px;
  position: relative;
  &-text {
    position: relative;
    span {
      position: absolute;
      top: 10px;
      right: 10px;
    }
  }
  &-image {
    width: max-content;
    overflow: hidden;
    border-radius: 1rem;
    img {
      border: 3px solid #fff;
      box-shadow: 0 0 15px -5px #111;
      position: absolute;
      z-index: 2;
      height: 250px;
      top: 50%;
      border-radius: 1rem;
      left: 0;
      transform: translateY(-50%);
    }
  }
  &-details {
    overflow: hidden;
    position: absolute;
    top: 0;
    .content {
      width: 100%;
      transition: 0.5s;
      padding-left: 50% !important;
      background-color: #fff;
      border-radius: 1rem;
      padding: 0.5rem;
      position: absolute;
      transform: translateX(-50%);
      bottom: 0;
      z-index: 1;
      height: 100%;
      span {
        opacity: 0;
        transition: 0.5s;
      }
    }
  }
  &:hover {
    .product-card {
      &-details {
        .content {
          transform: translateX(0);
          span {
            opacity: 1;
          }
        }
      }
    }
  }
}
</style>
